@import '@index';

.fragment-play {
  .item {
    display: flex;
    width: 100vw;
    .vision-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 150px 0 50px;
      @include normal() {
        width: calc(100vw - 950px);
      }
      @include lg() {
        padding: 0 100px;
      }
      @include sm() {
        padding: 0 50px;
      }
      > div {
        color: #fff;
        font-family: kallisto, sans-serif;
        font-size: 14px;
        font-weight: 400;
        h3 {
          color: var(--color-primary);
          font-weight: bold;
          text-transform: uppercase;
          margin-top: 40px;
          margin-bottom: 5px;
          font-size: 16px;
        }
        p {
          margin: 0;
          &:last-of-type {
            margin-bottom: 30px;
          }
          @include xs {
            text-align: justify;
          }
        }
      }
      .title {
        font-size: 50px;
        @include lg() {
          font-size: 40px;
          text-align: center !important;
        }
      }
    }
  }
}
