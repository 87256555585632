@import '@index';

.fragment-roadmap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: kallisto, sans-serif;
  color: #fff;
  height: var(--content-height);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include lg {
    padding: 50px 0;
  }
  .roadmap {
    display: flex;
    @include lg() {
      flex-direction: column;
    }
    .item {
      --hl: #898b90;
      --ll: #898b90;
      display: flex;
      @include normal() {
        border-left: 4px solid var(--ll);
      }
      @include lg() {
        border-bottom: 4px solid var(--ll);
      }
      position: relative;
      &.active {
        --ll: #fff;
        --hl: var(--color-primary);
      }
      @include normal() {
        flex-direction: column;
      }
      @include lg() {
        flex-direction: row;
      }
      h3 {
        color: var(--hl);
        margin-left: -5px;
        font-size: 25px;
        font-weight: bold;
        @include normal() {
          margin-top: -40px;
          margin-bottom: -20px;
        }
        @include lg() {
          margin-top: 50px;
          margin-bottom: -0;
        }
      }
      ul {
        list-style: none;
        padding-left: 30px;
        line-height: 40px;
        font-size: 15px;
        font-weight: 100;
        @include xs {
          margin-bottom: 50px;
        }
        li {
          position: relative;
          &::before {
            content: '';
            @include xs {
              content: unset;
            }
            background: var(--hl);
            width: 14px;
            height: 14px;
            position: absolute;
            border-radius: 50%;
            top: 13px;
            left: -39px;
          }
        }
      }
      &::after {
        content: '';
        width: 60px;
        height: 60px;
        position: absolute;
        background: radial-gradient(
          var(--hl) 20px,
          transparent 20px,
          transparent 26px,
          var(--ll) 27px
        );
        border-radius: 50%;
        bottom: -60px;
        @include lg {
          bottom: -30px;
        }
        @include normal {
          left: -30px;
        }
      }
      @include lg() {
        &:nth-child(odd) {
          &::after {
            right: -60px;
          }
        }
        &:nth-child(even) {
          &::after {
            left: -60px;
          }
        }
      }
      @include xs() {
        &:nth-child(odd) {
          &::after {
            left: -60px;
            right: unset;
          }
        }
        &:nth-child(even) {
          &::after {
            left: -60px;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        background-position: bottom;
        background-size: 5px 5px;
        background-repeat: repeat;
        @include normal() {
          width: calc(100% - 80px);
          height: 2px;
          left: 40px;
          bottom: -30px;
          background-image: linear-gradient(
            to right,
            white 33%,
            rgba(255, 255, 255, 0) 0%
          );
        }
        @include lg() {
          height: calc(100% - 81px);
          width: 2px;
          bottom: 40px;
          background-image: linear-gradient(
            to bottom,
            white 33%,
            rgba(255, 255, 255, 0) 0%
          );
        }
      }
      @include lg() {
        &:nth-child(odd) {
          &::before {
            right: -32px;
          }
        }
        &:nth-child(even) {
          &::before {
            left: -31px;
          }
        }
      }
      @include xs() {
        &:nth-child(odd) {
          &::before {
            right: unset;
            left: -31px;
          }
        }
      }
      @include normal() {
        &:nth-child(odd) {
          transform: translateY(calc(-50% - 30px));
        }
        &:nth-child(even) {
          transform: translateY(calc(50% + 30px));
          flex-direction: column-reverse;
          &::before {
            bottom: unset;
            top: -30px;
          }
          &::after {
            bottom: unset;
            top: -60px;
          }
          h3 {
            margin-top: -20px;
            margin-bottom: -40px;
          }
        }
      }
      @include lg() {
        flex-direction: column;
        &:nth-child(odd) {
          transform: translateX(calc(-50% - 30px));
        }
        &:nth-child(even) {
          transform: translateX(calc(50% + 30px));
        }
      }
      @include xs() {
        &:nth-child(odd) {
          transform: translateX(30px);
        }
        &:nth-child(even) {
          transform: translateX(30px);
        }
      }
    }
  }
}
