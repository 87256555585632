@import '@index';

.flyout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  font-family: kallisto, sans-serif;
  transition: all 0.5s;
  &.hidden {
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
    .content {
      right: -80vw;
      @include sm {
        right: -100vw;
      }
    }
  }
  .content {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0vw;
    bottom: 0;
    width: calc(80vw - 100px);
    @include sm {
      width: calc(100vw - 100px);
    }
    padding: 50px;
    transition: all 0.5s;
    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 40px;
        font-weight: bold;
      }
      a {
        color: inherit;
        font-size: 40px;
        height: 50px;
        line-height: 50px;
        width: 50px;
        border-radius: 50%;
        text-align: center;
        // background-color: var(--color-primary);
        // color: var(--color-primary);
      }
    }
    .scroll-box {
      height: calc(100vh - 100px - 100px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
