@import '@index';

.fragment-play {
  background-image: url(../../../public/images/bg_play.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include sm {
    background-size: cover;
    background-attachment: fixed;
  }
  > .row {
    height: var(--content-height);
    display: flex;
    flex-direction: row;
    @include lg() {
      flex-direction: column;
      padding: 20px 0 50px 0;
    }
    .background {
      flex: 1;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    @include normal() {
      .content {
        width: calc(100vw - 750px);
      }
    }
  }
  .title {
    margin: 0;
    color: #fff;
    text-align: right;
    text-transform: uppercase;
    font-family: kallisto, sans-serif;
    font-weight: bolder;
    font-size: 60px;
    @include sm {
      font-size: 40px;
    }
    @include xs {
      font-size: 30px;
    }
  }
}
