@import '@index';

.media-item {
  font-family: kallisto, sans-serif;
  @include normal() {
    width: calc(100vw - 950px);
  }
  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    @include sm() {
      flex-direction: column;
    }
    div {
      display: flex;
      flex-direction: column;
      padding: 0 50px;
      @include sm() {
        width: 80vw;
        padding: 0;
      }
      @include normal {
        width: calc(900px - 250px);
      }
      h3 {
        text-transform: uppercase;
        color: var(--color-primary);
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        @include sm {
          text-align: center;
          text-shadow: 0 0 3px #000;
        }
      }
      p {
        font-weight: 400;
        margin: 0;
        color: #fff;
        font-size: 14px;
        @include sm {
          text-align: justify;
          margin-bottom: 20px;
          text-shadow: 0 0 10px #000;
          overflow: hidden;
        }
      }
    }
    img {
      width: 442px;
      @include md-only {
        width: 350px;
      }
      @include sm-only {
        width: 350px;
        max-width: 80vw;
      }
      @include xs-only {
        width: 80vw;
      }
    }
  }
}
