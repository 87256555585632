@import '@index';

.fragment-whatis {
  font-family: kallisto, sans-serif;
  background-image: url(../../../public/images/bg_play.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include lg() {
    padding: 50px 0;
  }
  > .row {
    height: var(--content-height);
    display: flex;
    @include normal() {
      flex-direction: row;
    }
    @include lg() {
      flex-direction: column;
      align-items: center;
    }
    .background {
      flex: 1;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    @include normal() {
      .content {
        width: calc(100vw - 750px);
      }
    }
  }
  .contents {
    @include lg() {
      width: 100%;
    }
    .item {
      @include normal() {
        width: calc(100vw - 800px) !important;
      }
      .whatis-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 80vw;
        @include lg {
          padding-left: 10vw;
        }
        @include normal {
          padding-left: 50px;
          width: calc(100vw - 1000px) !important;
        }
        h3 {
          color: var(--color-primary);
          font-size: 40px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          margin-bottom: 20px;
          @include sm {
            font-size: 30px;
          }
          @include xs {
            font-size: 25px;
          }
        }
        p {
          margin: 0;
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          @include normal() {
            max-width: 60vw;
          }
          @include sm {
            text-align: justify;
          }
        }
      }
    }
  }
}
