@import '../index.scss';

.page-home {
  background-image: url('../../public/Background.png');
  background-color: var(--color-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1000;
  font-family: kallisto, sans-serif;
  @include lg() {
    height: calc(80vh - var(--sab));
    border-bottom: 2px solid var(--color-primary);
  }
  @include normal() {
    flex: 0 0 100%;
    height: 100vh;
  }

  #mouse {
    height: 50px;
    width: 25px;
    position: absolute;
    bottom: 230px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
    border: 2px solid #fff;
    transition: all 0.3s;
    animation: shake 8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-iteration-count: infinite;
    &::after {
      content: '';
      height: 7px;
      width: 3px;
      background: #fff;
      position: absolute;
      top: 10px;
      left: 11px;
      animation-name: mouseScroll;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      transition: all 0.3s;
    }
    &::before {
      position: absolute;
      top: 60px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      width: 150px;
      transform: translateX(-50%);
      content: 'Start Browsing';
      transition: all 0.3s;
    }
    // &:hover {
    //   width: 150px;
    //   background: var(--color-primary);
    //   border-color: transparent;
    //   border-radius: 5px;
    //   &::before {
    //     opacity: 1;
    //   }
    //   &::after {
    //     opacity: 0;
    //   }
    // }
  }
  @keyframes mouseScroll {
    0% {
      top: 10px;
    }
    75% {
      top: 15px;
      // height: 7px;
    }
    76% {
      // height: 3px;
    }
    99% {
      // height: 3px;
    }
    100% {
      // height: 7px;
      top: 10px;
    }
  }

  @keyframes shake {
    1% {
      transform: translateX(calc(-50% + -1px));
      border-color: var(--color-primary);
    }
    2% {
      transform: translateX(calc(-50% + 2px));
    }
    3% {
      transform: translateX(calc(-50% + -4px));
    }
    4% {
      transform: translateX(calc(-50% + 4px));
    }
    5% {
      transform: translateX(calc(-50% + -4px));
    }
    6% {
      transform: translateX(calc(-50% + 4px));
    }
    7% {
      transform: translateX(calc(-50% + -4px));
    }
    8% {
      transform: translateX(calc(-50% + 2px));
    }
    9% {
      transform: translateX(calc(-50% + -1px));
      border-color: var(--color-primary);
    }
    10% {
      border-color: #fff;
      transform: translateX(-50%);
    }
  }
  & > svg,
  & > img {
    position: absolute;
    top: 50%;
    height: 20vw;
    &:first-of-type {
      left: 0;
      transform: translateX(-50%) translateY(-50%);
    }
    &:last-of-type {
      right: 0;
      transform: translateX(50%) translateY(-50%);
    }
  }
  & > div {
    height: 100vh;
    align-items: center;
    & > a {
      position: absolute;
      bottom: 174px;
      left: 50%;
      transform: translateX(-50%);
      svg {
        // cursor: pointer;
        // bottom: 180px;
        // position: absolute;
        &:hover path {
          fill: var(--color-primary-hover);
        }
      }
    }
    .btn {
      margin-top: 15px;
    }
  }
  .social {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    gap: 50px;
    flex-wrap: wrap;
    width: calc(100% - 50px);
    justify-content: center;
  }
  .countdown {
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 50px;
    font-size: 2.5rem;
    margin-top: calc(50vh + 140px);
    & > span {
      & > small {
        display: block;
        font-size: 0.75rem;
        opacity: 0.5;
      }
    }
  }
  @include respond-below(sm) {
    & > div {
      height: 100%;
      height: -webkit-fill-available;
    }
  }

  @include respond-below(md) {
    .countdown {
      transform: scale(0.9);
    }
  }
  @include respond-above(sm) {
    .logo_small {
      display: none;
    }
    .logo_large {
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @include respond-below(sm) {
    .logo_small {
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .logo_large {
      display: none;
    }
    .countdown {
      transform: scale(0.7);
    }
  }
  @include respond-above(lg) {
    & > div {
      img {
        top: 50%;
        width: 54vw;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @include respond-below(lg) {
    & > img {
      display: none;
    }
    div > img {
      width: 80%;
    }
    .countdown {
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0.9);
      left: 50%;
    }
    .social {
      bottom: 50px;
      a {
        > span {
          display: none;
        }
      }
    }
  }
  @include respond-below(sm) {
    .countdown {
      transform: translateY(-50%) translateX(-50%) scale(0.7);
    }
    .social {
      gap: 20px;
    }
    .socialmedia-link {
      transform: scale(0.7);
    }
  }
}
