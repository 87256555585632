// https://www.bdvom.de/media-queries-ueber-scss-funktion/
/**
 * Media Queries for Bootstrap and VisualComposer / WPBakery
 * it is also possible to just write a number as min and max value
**/
// A map of breakpoints.
$breakpoints: (
  //Bootstrap
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  //Visual Composer / WPBakery
  vc-sm: 768px,
  vc-md: 992px,
  vc-lg: 1200px
);
/**
 *  RESPOND ABOVE
**/
// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @error 'Invalid breakpoint: #{$breakpoint}.';
    @if (is-number($breakpoint)) {
      $breakpoint-value: $breakpoint + 'px';
      // Write the media query.
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }
  }
}
/**
 *  RESPOND BELOW
**/
// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @error 'Invalid breakpoint: #{$breakpoint}.';
    @if (is-number($breakpoint)) {
      $breakpoint-value: $breakpoint + 'px';
      // Write the media query.
      @media (max-width: ($breakpoint-value)) {
        @content;
      }
    }
  }
}
/**
 * RESPOND BETWEEN
**/
// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @error 'Your lower breakpoint was invalid: #{$lower}.';
    }
    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @error 'Your upper breakpoint was invalid: #{$upper}.';
    }
    @if (is-number($lower) and is-number($upper)) {
      $lower-breakpoint: $lower + 'px';
      $upper-breakpoint: $upper + 'px';
      // Write the media query.
      @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
        @content;
      }
    }
    @if (
      is-absolute-length-in-px($lower) and is-absolute-length-in-px($upper)
    ) {
      $lower-breakpoint: $lower;
      $upper-breakpoint: $upper;
      // Write the media query.
      @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
        @content;
      }
    }
  }
}
@function is-number($value) {
  @return type-of($value) == 'number';
}
@function is-absolute-length-in-px($value) {
  @return is-number($value) and index('px', unit($value)) != null;
}

@mixin normal {
  @include respond-above(lg) {
    @content;
  }
}

@mixin lg {
  @include respond-below(lg) {
    @content;
  }
}
@mixin lg-only {
  @include respond-between(md, lg) {
    @content;
  }
}

@mixin md {
  @include respond-below(md) {
    @content;
  }
}
@mixin md-only {
  @include respond-between(sm, md) {
    @content;
  }
}

@mixin sm {
  @include respond-below(sm) {
    @content;
  }
}
@mixin sm-only {
  @include respond-between(xs, sm) {
    @content;
  }
}

@mixin xs {
  @include respond-below(xs) {
    @content;
  }
}
@mixin xs-only {
  @include xs {
    @content;
  }
}
