@import '@index';

.fragment-news {
  font-family: kallisto, sans-serif;
  width: calc(
    100vw - var(--content-height) + ((var(--content-height) / 2) * 4)
  );
  .row {
    display: flex;
    .image {
      width: calc(100vw - var(--content-height));
      height: var(--content-height);
      background-position: center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.54);
      &.small {
        width: calc(80vw - var(--content-height));
      }
      span {
        color: var(--color-primary);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 23px;
        @include sm {
          font-size: 15px;
        }
      }
      h2 {
        margin: 0;
        color: #fff;
        text-transform: uppercase;
        font-size: 46px;
        @include sm {
          font-size: 30px;
        }
        @include sm {
          font-size: 25px;
          padding: 0 25px;
        }
      }
      small {
        color: #fff;
        font-weight: bold;
        padding-top: 30px;
        display: block;
      }
      p {
        color: #fff;
        padding-top: 30px;
        display: block;
        @include normal {
          width: 80%;
          margin-left: 10%;
        }
        @include lg {
          width: 80vw;
          margin-left: 10vw !important;
          text-shadow: 0 0 10px rgba(0, 0, 0, 1);
          margin-left: 0;
          // text-align: justify;
        }
      }
    }
    .news {
      height: var(--content-height);
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      .news-item {
        height: calc(var(--content-height) / 2);
        aspect-ratio: 2/1;
        background: #fff;
        display: flex;
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
        > div {
          height: calc(var(--content-height) / 2);
          aspect-ratio: 1;
        }
        .text {
          padding: 50px;
          height: calc(calc(var(--content-height) / 2) - 100px);
          display: flex;
          flex-direction: column;
          @include xs {
            padding: 10vw;
          }
          h3 {
            font-weight: bold;
            text-transform: uppercase;
            color: var(--color-primary);
            font-size: 21px;
            margin: 0;
          }
          h2 {
            text-transform: uppercase;
            font-size: 32px;
            flex: 1;
            margin: 0;
            line-height: 30px;
            margin-top: 10px;
          }
          span {
            font-weight: bold;
            font-size: 15px;
          }
          p {
            font-size: 12px;
          }
          &.soon {
            justify-content: space-between;
            h4 {
              text-align: center;
              align-self: center;
              text-transform: uppercase;
              font-size: 20px;
            }
          }
        }
        .side {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
  @include lg() {
    > .row {
      flex-direction: column;
      > div {
        width: 100%;
      }
      .image {
        width: 100%;
        height: auto;
        padding: 150px 0;
        > div {
          width: 100vw;
        }
      }
      .news {
        .news-item {
          height: auto;
          aspect-ratio: unset;
          width: 100%;
          @include sm {
            &:nth-of-type(even) {
              background: #112131;
              color: #fff;
            }
          }
          & ~ .soon {
            display: none;
          }
          .text {
            width: 100%;
            max-height: calc(300px - 100px) !important;
            overflow: hidden;
            @include xs {
              max-height: unset !important;
            }
          }
          div {
            height: auto !important;
            aspect-ratio: unset;
          }
          .side {
            height: 300px !important;
            aspect-ratio: 1;
            @include sm {
              height: 0px !important;
            }
          }
        }
      }
    }
  }
}
