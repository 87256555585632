@import '@index';

.fragment-partners {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  > .partners {
    display: flex;
    font-family: kallisto, sans-serif;
    height: 100%;
    @include lg() {
      flex-direction: column;
    }
    .info {
      @include normal() {
        width: calc(100vw - var(--content-height));
      }
      @include lg() {
        padding: 100px;
      }
      @include xs {
        padding: 10vw;
      }
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: center;
      align-items: center;
      background-size: 0;
      background-position: center;
      background-repeat: no-repeat;
      text-transform: uppercase;
      span {
        color: var(--color-primary);
        font-size: 20px;
        font-weight: bold;
      }
      h2 {
        font-size: 55px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 200px;
      }
      small {
        color: var(--color-primary);
        font-size: 17px;
      }
      a {
        font-size: 23px;
        color: inherit;
        text-decoration: none;
        font-weight: bold;
        @include xs {
          font-size: 15px;
        }
        b {
          color: var(--color-primary);
        }
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      height: var(--content-height);
      @include normal() {
        width: var(--content-height);
      }
      .scroll-box {
        width: 100%;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
      }
      .item {
        aspect-ratio: 1;
        @include normal() {
          width: 50%;
        }
        @include lg() {
          width: 25vw;
        }
        @include md() {
          width: 50vw;
        }
        background: #f7f8fa;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &:nth-child(3n + 1) {
          background: #fff;
        }
        img {
          width: 80px;
        }
        h3 {
          font-size: 25px;
          margin: 25px 0;
          font-weight: bold;
          text-transform: uppercase;
        }
        h4 {
          font-size: 25px;
          font-weight: bold;
          text-transform: uppercase;
          @include xs {
            font-size: 15px;
          }
        }
        > a {
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.5);
          font-size: 13px;
          text-decoration: none;
        }
        p {
          width: 80%;
          opacity: 0.5;
        }
      }
    }
  }
}
