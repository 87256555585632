@import '@index';

.leader-boards {
  display: flex;
  font-family: kallisto, sans-serif;
  flex-direction: column;
  padding: 0 100px;
  @include sm() {
    padding: 50px;
    width: calc(100vw - 100px);
  }
  @include normal() {
    width: calc(100vw - 1000px);
  }
  @include xs {
    width: 80vw;
    padding: 0 10vw;
  }
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 50px;
  .leader-board {
    display: flex;
    gap: 50px;
    @include xs() {
      gap: 10px;
      flex-direction: column;
      width: 100%;
      img {
        width: 100% !important;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        color: var(--color-primary);
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 10px;
        font-size: 16px;
        @include xs {
          text-align: center;
        }
      }
      p {
        color: #fff;
        font-weight: 400;
        margin: 0;
        font-size: 14px;
        @include sm {
          text-align: justify;
        }
      }
    }
    img {
      width: 352px;
      @include sm {
        width: 40vw;
      }
      @include xs {
        margin-top: 20px;
        width: 80vw !important;
      }
    }
  }
}
