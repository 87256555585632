@import '@index';

#tradingcardScroller {
  height: 100%;
  @include lg() {
    width: 100vw;
  }
  @include normal() {
    width: calc(100vw - 950px);
  }
  .trading-card {
    font-family: kallisto, sans-serif;
    img {
      border-radius: 10px;
      height: 45vh;
    }
    h3 {
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
    }
    h4 {
      font-weight: 400;
      margin: 0;
      margin-top: 10px;
      text-align: center;
      color: #fff;
    }
  }
}
