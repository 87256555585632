@import '@index';

.super-scroll {
  display: flex;
  width: 100vw;
  height: 100%;
  align-items: center;
  @include lg() {
    flex-direction: column-reverse;
  }
  .categories {
    display: flex;
    gap: 50px;
    margin-top: 50px;
    @include xs() {
      gap: 0;
      justify-content: space-between;
      width: 80vw;
    }
    @include normal() {
      padding-left: 50px;
      flex-direction: column;
    }
    a {
      color: #5e627e;
      width: 47px;
      height: 47px;
      text-align: center;
      line-height: 47px;
      font-size: 16px;
      border: 2px solid #5e627e;
      border-radius: 50%;
      position: relative;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        border-color: var(--color-primary);
        color: #fff;
        > svg #icon {
          fill: #fff;
        }
        > svg #strokecon {
          stroke: #fff;
        }
      }
      &:not(:last-of-type)::after {
        content: '';
        position: absolute;
        background-position: right;
        background-size: 5px 5px;
        background-repeat: repeat;
        @include normal() {
          background-image: linear-gradient(
            #5e627e 33%,
            rgba(255, 255, 255, 0) 0%
          );
          height: 40px;
          width: 2px;
          left: 50%;
          margin-left: -2px;
          top: 53px;
        }
        @include lg() {
          background-image: linear-gradient(
            90deg,
            #5e627e 33%,
            rgba(255, 255, 255, 0) 0%
          );
          width: 40px;
          height: 2px;
          top: 50%;
          margin-top: -1px;
          left: 55px;
        }
        @include xs() {
          width: calc(((80vw - (50px * 5)) / 5) - 9px);
          background: transparent;
        }
      }
    }
  }
  .contents {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: var(--content-height);
    overflow: hidden;
    @include lg() {
      margin-top: 50px;
    }
    .item {
      @include normal() {
        transition: all 0.3s;
        height: 100%;
      }
      overflow: hidden;
      &:not(.active) {
        height: 0;
        transform: scale(0);
        opacity: 0;
      }
    }
  }
}
