@import '@index';

.navbar {
  position: fixed;
  height: var(--nb-height);
  width: calc(100vw - 30px);
  background: #121c26;
  z-index: 100;
  padding: 15px;
  display: flex;
  font-family: kallisto, sans-serif;
  gap: 35px;

  &.dashed {
    ul li {
      &:not(:last-of-type) {
        padding-right: 70px;
        &:after {
          content: '';
          height: 5px;
          width: 40px;
          background: var(--color);
          position: absolute;
          border-radius: 5px;
          margin-top: 8px;
          margin-left: 15px;
        }
      }
    }
  }

  .navbar-inner {
    display: flex;
    font-family: kallisto, sans-serif;
    gap: 150px;
    width: 100%;
  }

  img {
    height: 42px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    margin: 10px 0;
    flex: 1;

    li {
      --color: #fff;
      position: relative;
      &:not(:last-of-type) {
        padding-right: 30px;
      }
      a {
        color: var(--color);
        text-decoration: none;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
      }
      &.active {
        a {
          color: var(--color-primary);
        }
        &:not(:last-of-type) {
          &:after {
            --color: rgba(255, 255, 255, 0.5);
          }
        }
        & ~ li {
          a {
            --color: rgba(255, 255, 255, 0.5);
          }

          &:not(:last-of-type) {
            &:after {
              --color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }
  &.dashed {
    ul li {
      &:not(:last-of-type) {
        padding-right: 60px;
        &:after {
          content: '';
          height: 5px;
          width: 30px;
          background: var(--color);
          position: absolute;
          border-radius: 5px;
          margin-top: 8px;
          margin-left: 15px;
        }
      }
    }
  }
  .whitepaper {
    margin-right: 10px;
  }
  .nav {
    // margin-top: 10px;
    margin: 10px -10px;
  }
  .social {
    padding: 20px 20px;
    margin-top: -15px;
  }
  .navbar-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .btn {
      margin-top: -2px;
    }
  }
  @include normal {
    .social-box {
      z-index: -1;
      position: absolute;
      right: 0;
      bottom: 102px;
      display: flex;
      flex-direction: column;
      gap: 2.5px;
      transition: all 0.3s;
      padding-bottom: 2.5px;
      a {
        color: #fff;
        font-size: 20px;
        padding: 18px 18px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        align-items: center;
        display: flex;
        transition: all 0.3s;
        border-left-width: 10px;
        border-left-style: solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        &:not(:hover) {
          background: #121c26 !important;
        }
        b {
          height: 0;
          overflow: hidden;
        }
        > span {
          display: flex;
          flex-direction: column;
          color: #fff;
        }
        small,
        > span > span {
          overflow: hidden;
          transition: all 0.3s;
          height: 24px;
          font-size: 20px;
        }
        .icon {
          margin-right: 15px;
          font-size: 35px;
          width: 50px;
        }
        &:not(:hover) > span > small {
          height: 0;
        }
        &:hover > span > span {
          height: 10px;
          font-size: 8px;
        }
      }
    }
    .social:not(:hover) + .social-box {
      opacity: 0;
      transform: translateX(200%);
    }
    .social-box:hover {
      opacity: 1 !important;
      transform: translateX(0%) !important;
      z-index: 100000000000000000;
    }
  }
  &:hover {
    z-index: 100000000000000000;
  }
  .nav-filter {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    padding-top: 24px;
    margin-top: -13px;
    @include normal {
      width: 25px;
    }
    .filter-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 100px;
      background: #121c26;
      padding: 15px 20px;
      margin-left: -20px;
      gap: 20px;
      transition: all 0.3s;
      a {
        color: inherit;
        text-decoration: none;
        &.active {
          color: var(--color-primary);
        }
      }
    }
    &:not(:hover) .filter-menu {
      opacity: 0;
      pointer-events: none;
      transform: translateY(10px);
    }
  }
  @include normal {
    margin-left: -100vw;
    margin-top: calc(100vh - var(--nb-height));
    .logo_large {
      display: none;
    }
    > button {
      display: none;
    }
    > div.dummy {
      display: none;
    }
  }
  &.show {
    z-index: 100000000000000000;
  }
  .navbar-buttons {
    gap: 30px;
  }
  .nav-right,
  .nav-left {
    padding-top: 24px;
    margin-top: -13px;
    width: 20px;
    text-align: center;
  }
  @include lg {
    height: 50px;
    padding: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    padding-bottom: var(--sab);
    > div.dummy {
      aspect-ratio: 1;
    }
    button {
      background: transparent;
      border: none;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      span {
        width: 25px;
        height: 2px;
        background: var(--color-primary);
        transition: all 0.3s;
      }
    }
    &.show button {
      span:nth-of-type(1) {
        transform: translateY(7px) rotate(calc(45deg));
      }
      span:nth-of-type(2) {
        transform: scale(0) translateX(-20px);
      }
      span:nth-of-type(3) {
        transform: translateY(-7px) rotate(calc(-45deg));
      }
      // span:nth-of-type(1) {
      // height: 4px;
      // transform: translateY(5px) scale(0.5) rotate(calc(30deg));
      // }
      // span:nth-of-type(2) {
      // transform: scaleX(0.2) translateX(-40px);
      // }
      // span:nth-of-type(3) {
      // height: 4px;
      // transform: translateY(-5px) scale(0.5) rotate(calc(-30deg));
      // }
    }
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: calc(50px + var(--sab));
      z-index: -1;
      background: rgba(0, 0, 0, 0.7);
      transition: all 0.3s;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: #121c26;
      transition: all 0.3s;
    }
    &:not(.show) {
      &:before {
        opacity: 0;
        pointer-events: none;
      }
    }
    .logo_small {
      display: none;
    }
    .logo_large {
      height: 50px;
    }
    .nav-right,
    .nav-left {
      display: none;
    }
    .navbar-buttons {
      flex-direction: column;
      gap: 20px;
    }
    .navbar-inner {
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      bottom: calc(50px + var(--sab));
      width: auto;
      background: #121c26;
      padding-bottom: 20px;
      transition: all 0.3s;
      @include xs {
        width: 100%;
      }
      ul {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        li {
          padding: 0 !important;
          a {
            line-height: 50px;
            display: block;
            padding: 0 50px 0 20px;
          }
          &::after {
            display: none;
          }
        }
      }
      .btn {
        margin: 0 20px;
      }
      .social {
        display: none;
      }
      .social-box {
        display: flex;
        justify-content: space-evenly;
        a {
          aspect-ratio: 1;
          text-align: center;
          line-height: 50px;
          height: 50px;
          color: #fff;
          font-size: 25px;
          border-radius: 10px;
          span {
            display: none;
          }
        }
      }
      .nav-filter {
        color: transparent;
        margin: 0;
        padding: 0;
        height: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        > span {
          display: none;
        }
        .filter-menu {
          color: #fff;
          flex-direction: row;
          opacity: 1;
          transform: none;
          margin: 0 0 0 0;
          bottom: unset;
        }
      }
    }
    &:not(.show) {
      .navbar-inner {
        transform: translateX(100%);
        &::before {
          opacity: 0;
          left: 100vw;
          pointer-events: none;
        }
      }
    }
  }
}
