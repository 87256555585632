@import '@index';

footer {
  background: #121c26;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: kallisto, sans-serif;
  position: relative;
  @include normal() {
    width: 400px;
    border-left: 8px solid var(--color-primary);
    height: var(--content-height) !important;
  }
  @include lg() {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .socialmedia {
    display: flex;
    @include normal() {
      flex-direction: column;
    }
    gap: 50px;
    text-align: center;
    a {
      font-size: 50px;
      color: #fff;
      &:hover {
        color: var(--color-primary);
      }

      @include xs {
        font-size: 35px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 50px;
      }
    }
  }
  .footer {
    color: #fff;
    text-align: center;
    width: 100%;
    line-height: 30px;
    small {
      font-size: 12px;
      margin-bottom: 15px;
      display: block;
      b {
        color: var(--color-primary);
        font-size: inherit;
      }
    }
    .links {
      display: flex;
      gap: 10px 20px;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;
      a {
        color: inherit;
      }

      @include xs {
        padding: 10vw;
      }
    }
  }
}
