@import './index.scss';
:root {
  --color-primary: #96d14f;
  --color-primary-dark: #a8eb56;
  --color-primary-hover: #b6e979;

  --color-default: #5e627e;
  --color-default-dark: #717597;

  --color-error: #cc5050;
  --color-error-dark: #e26262;

  --color-success: #54cc50;
  --color-success-dark: #62e273;

  --color-warning: #c97136;
  --color-warning-dark: #e47f3c;

  --color-info: #50b3cc;
  --color-info-dark: #58c3dd;

  --color-bg: #0b151f;
  --nb-height: 72px;
  --content-height: calc(100vh - var(--nb-height));
  @include lg() {
    --content-height: auto;
  }
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}
html,
body {
  @include normal() {
    height: 100vh;
    overflow-y: hidden;
  }
  overflow-x: hidden;
  width: 100vw;
  padding: 0;
  margin: 0;
}
#root > #main,
main {
  display: flex;
  @include normal() {
    flex-direction: row;
  }
  @include lg() {
    flex-direction: column;
  }
}
#root > #main {
  display: flex;
  @include normal() {
    flex-direction: row;
  }
  @include lg() {
    flex-direction: column;
  }
}
html,
body,
.page,
#root,
#root > #main,
#content {
  @include normal() {
    overflow-y: hidden;
    height: -webkit-fill-available;
  }
  @include lg {
  }
}
.fixed-width {
  width: 100vw;
}
.dynamic-width {
  min-width: 100vw;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #112233;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-hover);
}
[data-tooltip] {
  position: relative;
}
[data-tooltip]::before {
  content: attr(data-tooltip);
  /* content: 'penis'; */
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  padding: 0 10px;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  pointer-events: none;
  z-index: 10000;
  border-radius: 5px;
}
[data-tooltip]:not(:hover)::before {
  opacity: 0;
}

#debug {
  font-family: kallisto, sans-serif;
  &:before {
    position: fixed;
    content: 'Debug';
    top: 0;
    left: 0;
    z-index: 1000000000000000000;
    color: var(--color-primary);
    background: #000;
    padding: 10px 15px;
    font-weight: bold;
  }

  @include respond-above(lg) {
    &:before {
      content: '> LG';
    }
  }
  @include respond-below(lg) {
    &:before {
      content: '< LG';
    }
  }
  @include respond-below(md) {
    &:before {
      content: '< MD';
    }
  }
  @include respond-below(sm) {
    &:before {
      content: '< SM';
    }
  }
  @include respond-below(xs) {
    &:before {
      content: '< XS';
    }
  }
}
#currentDev {
  display: none;
  position: absolute;
  background: var(--color-error);
  border: 2px solid var(--color-error-dark);
  border-top: none;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000000000000;
  color: #fff;
  font-weight: bold;
  font-family: kallisto, sans-serif;
  padding: 10px 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
