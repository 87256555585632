@import '@index';

.content-scroll {
  display: flex;
  @include normal() {
    height: 100%;
  }
  align-items: center;

  .content {
    flex: 1;
    position: relative;
    @include lg() {
      padding: 0 50px 150px 50px;
    }
    @include normal() {
      width: calc(100vw - 950px) !important;
    }
    @include xs() {
      width: 80vw !important;
      overflow: hidden;
      padding: 0 10vw 50px 10vw;
    }
    &::after {
      content: attr(data-title);
      margin: 0;
      color: #fff;
      text-align: right;
      text-transform: uppercase;
      font-family: kallisto, sans-serif;
      font-weight: bolder;
      font-size: 60px;
      position: absolute;
      bottom: -50px;
      @include lg() {
        bottom: 50px;
        text-align: center;
        left: 0;
      }
      right: 50px;
      @include sm {
        right: unset;
        left: 0;
        width: calc(100vw - 50px);
        font-size: 40px;
        padding: 0 25px;
      }
      @include xs {
        width: calc(100vw - 50px);
        font-size: 30px;
        bottom: 0;
      }
    }
    > div {
      transition: all 0.3s;
      max-height: 300px;
      @include lg() {
        margin-bottom: 50px;
      }
      @include sm() {
        max-height: 100vh;
      }
      &.deamon {
        opacity: 0 !important;
        pointer-events: none;
        @include lg() {
          display: none;
        }
      }
      img {
        transition: all 0.3s;
      }
      @include normal() {
        &.active {
          margin-top: -100px;
          margin-bottom: -100px;
          z-index: 1;
          position: relative;
        }
        &:not(.active) {
          opacity: 0.5;
          transform: scale(0.8) translateX(70px);
          img {
            filter: blur(3px);
            -webkit-filter: blur(3px);
          }
        }
        &:not(.active):not(.sib) {
          opacity: 0;
          transform: scale(0) translateX(70px);
          max-height: 0 !important;
        }
      }
    }
  }
  .scroll {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    gap: 5px;
    width: 100px;
    a {
      &.active #Ellipse_1 {
        fill: var(--color-primary);
        stroke: #fff;
      }
      &:last-of-type > svg {
        transform: rotate(180deg);
      }
    }
    @include lg() {
      display: none;
    }
  }
}
