@import '@index';

.content-h-scroll {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;

  .content {
    flex: 1 1;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    &::after {
      content: attr(data-title);
      margin: 0;
      color: #fff;
      text-align: right;
      text-transform: uppercase;
      font-family: kallisto, sans-serif;
      font-weight: bolder;
      font-size: 60px;
      position: absolute;
      @include normal() {
        bottom: 50px;
      }
      @include lg() {
        bottom: -30px;
        text-align: center;
        left: 0;
        width: 100vw;
      }
      right: 50px;
      width: calc(100% - 100px);
      text-align: center;
      @include md() {
        text-align: center;
        font-size: 40px;
        bottom: -15px;
      }
      @include xs {
        font-size: 30px;
      }
    }
    > div {
      display: flex;
      width: calc(100vw - 700px - 155px);
      @include lg() {
        width: 100vw;
      }
      margin-left: 50px;
      @include sm {
        margin-left: 0;
      }
      justify-content: center;
      > div {
        transition: all 0.3s;
        max-width: 300px;
        // pointer-events: none;
        &.deamon {
          opacity: 0 !important;
          pointer-events: none;
        }
        img {
          transition: all 0.3s;
        }
        &.active {
          margin-left: -100px;
          margin-right: -100px;
          z-index: 1;
          position: relative;
        }
        &:not(.active) {
          opacity: 0.5;
          transform: scale(0.8) translateY(20px);
          img {
            filter: blur(3px);
            -webkit-filter: blur(3px);
          }
        }
        &:not(.active):not(.sib) {
          opacity: 0;
          transform: scale(0) translateX(20px);
          max-width: 0;
        }
      }
    }
  }
  .scroll {
    display: flex;
    flex-direction: row;
    gap: 5px;
    @include normal() {
      bottom: 150px;
    }
    @include lg() {
      bottom: 60px;
    }
    position: relative;
    z-index: 1;
    a {
      &.active #Ellipse_1 {
        fill: var(--color-primary);
        stroke: #fff;
      }
      &:last-of-type > svg {
        transform: rotate(90deg);
      }
      &:first-of-type > svg {
        transform: rotate(-90deg);
      }
    }
  }
}
