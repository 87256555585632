@import '@index';

.fragment-media {
  background-color: #0b151f;
  overflow: hidden;

  background-image: url('../../../public/images/bg_play.jpg');
  background-color: var(--color-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  .media-item {
    justify-content: center;
    align-items: center;
    display: flex;
    @include normal() {
      width: 100%;
      margin-top: -150px;
    }
    > div {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .content-scroll {
    height: var(--content-height);
    justify-content: center;
    width: 100vw;

    .content {
      padding-top: 130px;
      > div {
        margin-bottom: 0;
        img {
          border: 5px solid var(--color-primary);
        }
        iframe {
          aspect-ratio: 16/9;
        }
        &:not(.active) {
          transform: scale(0.8) translateX(0px) !important;
        }
      }
      &::after {
        bottom: unset;
        right: unset;
        @include normal {
          transform: rotate(-90deg) translateY(-50%);
          top: 50%;
          left: 0;
        }
        @include lg {
          top: 150px;
          width: 100vw;
          text-align: center;
        }
        @include md-only {
          top: 100px;
        }
        @include sm {
          top: 50px;
          padding: 0;
        }
        @include xs-only {
          // top: 0;
        }
      }
    }

    @include normal() {
      .content {
        width: calc((70vh / 9 * 16) + 250px);
        > div {
          max-height: 70vh !important;
          img {
            height: 70vh;
          }
          iframe {
            height: 70vh;
          }
        }
      }
    }
    @include lg() {
      .content {
        width: 100vw;
        > div {
          max-height: 70vh !important;
          img {
            width: 80vw;
          }
          iframe {
            width: 80vw;
          }
        }
      }
    }

    > .scroll {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      z-index: 1;
    }
  }
}
