.socialmedia-link {
  color: #fff;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  font-family: kallisto, sans-serif;
  svg {
    color: var(--color-primary);
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  & > span {
    text-transform: uppercase;
    text-align: left;
    span {
      display: block;
      font-size: 14px;
    }
    b {
      display: block;
      font-weight: bold;
      font-size: 30px;
    }
  }
}
