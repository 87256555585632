.btn {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  font-weight: boldo;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  padding: 0 30px;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s;
  color: #fff;
  text-align: center;
  border: 3px solid transparent;
  &.rounded {
    border-radius: 40px 5px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.primary:not(.outline) {
    color: #000;
    background: var(--color-primary);
    &:hover {
      background: var(--color-primary-dark);
    }
  }

  &.primary.outline {
    border-color: var(--color-primary);
    &:hover {
      border-color: var(--color-primary-dark);
    }
  }

  &.outline {
    color: #fff;
    border: 3px solid transparent;
  }

  $types: default, error, success, warning, info;

  @each $type in $types {
    &.#{$type}:not(.outline) {
      border-color: var(--color-#{$type});
      background: var(--color-#{$type});
      &:hover {
        background: var(--color-#{$type}-dark);
        border-color: var(--color-#{$type}-dark);
      }
    }
    &.#{$type}.outline {
      border-color: var(--color-#{$type});
      &:hover {
        border-color: var(--color-#{$type}-dark);
      }
    }
  }
}
