@import '@index';

.fragment-team {
  font-family: kallisto, sans-serif;
  position: relative;
  height: var(--content-height);
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include lg {
    border-top: 2px solid var(--color-primary);
  }
  .content {
    @include normal {
      height: var(--content-height);
    }
    @include lg {
      padding-top: 250px;
    }
    .member {
      overflow: hidden;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr 5fr;
      justify-content: stretch;
      place-items: center;
      align-items: stretch;
      gap: 50px;
      @include normal {
        height: calc(var(--content-height) - 50px);
        width: calc(100vw - 200px);
        grid-template:
          'info person'
          'favourites image';
      }
      @include lg {
        grid-template:
          'person person'
          'info info'
          'favourites image';
        margin-bottom: 0 !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
      &.active {
        @include normal {
          margin: 50px 100px 0 50px;
        }
        @include lg {
          grid-template-rows: 0.3fr 0.3fr 1.5fr;
          margin: 0;
        }
      }

      .info {
        grid-area: info;
        @include normal {
          margin-top: 100px;
        }
        @include lg {
          text-align: center;
        }
        span {
          color: var(--color-primary);
          font-size: 20px;
          font-weight: bold;
          margin: 0;
        }
        h3 {
          text-transform: uppercase;
          font-size: 90px;
          font-weight: bold;
          margin: 0;
          @include lg {
            font-size: 40px;
          }
          @include xs {
            font-size: 30px;
          }
        }
      }
      .favorites {
        grid-area: favourites;
        @include normal {
          padding-top: 70px;
        }
        @include lg {
          width: 50vw;
          margin-right: -15vw;
        }
        div {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          gap: 100px;
          @include lg {
            grid-template-columns: unset;
            grid-template-rows: unset;
            gap: 30px;
            align-items: right;
          }
          .favorite {
            display: flex;
            text-transform: uppercase;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 0;
            small {
              font-size: 20px;
              font-weight: bold;
              opacity: 0.5;
              @include sm {
                font-size: 10px;
              }
            }
            span {
              font-size: 40px;
              @include sm {
                font-size: 20px;
              }
              font-weight: bold;
              color: var(--color-primary);
            }
          }
        }
      }
      .person {
        grid-area: person;
        text-align: center;
        @include lg {
          grid-column: span 2;
        }
        @include normal {
          padding-top: 70px;
        }
        small {
          text-transform: uppercase;
          font-size: 20px;
          font-weight: bold;
          color: var(--color-primary);
        }
        h2 {
          font-size: 60px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          @include xs {
            font-size: 30px;
          }
        }
        .socialmedia {
          gap: 30px;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          a {
            color: var(--color-primary);
            font-size: 20px;
          }
        }
      }
      .image {
        --imageHeight: 63vh;
        display: flex;
        grid-area: image;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        background-image: url(../../../public/images/TeamBackground.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: var(--imageHeight);
        position: relative;
        height: 100%;
        @include normal {
          width: calc(var(--imageHeight) * 1.123693);
          margin-top: -30px;
        }
        @include lg {
          width: calc(100vw + 100px);
          margin: 0 -50px 0 -50px;
          width: 50vw;
          height: unset;
          background: transparent;
          margin: 0;
          border-bottom: 1px solid var(--color-primary);
        }
        img {
          height: var(--imageHeight);
          @include lg {
            width: 50vw;
            height: unset;
          }
          @include xs {
            width: 70vw;
          }
        }
      }
      &:not(.active) {
        height: 0;
      }
    }
  }
  .select {
    position: absolute;
    display: flex;
    flex-direction: column;
    @include normal {
      top: 120px;
      right: 50px;
    }
    @include lg {
      top: 55px;
      right: 50px;
      flex-direction: row;
    }
    a {
      display: flex;
      height: 130px;
      aspect-ratio: 1;
      pointer-events: none;
      @include normal {
        margin-top: -65px;
      }
      @include lg {
        margin-left: -65px;
      }
      position: relative;
      &.active {
        z-index: 1;
        & > span {
          border-color: var(--color-primary);
        }
      }
      &:nth-child(even) {
        @include normal {
          margin-left: -65px;
        }
        @include lg {
          margin-top: 65px;
        }
      }
      > span {
        pointer-events: all;
        display: flex;
        height: 80px;
        width: 80px;
        overflow: hidden;
        background-color: #151720;
        border: 6px solid #222430;
        transform: rotate(45deg);
        > span {
          pointer-events: none;
          position: absolute;
          transform: rotate(-45deg);
          height: 110px;
          width: 110px;
          background-position: bottom center;
          background-size: cover;
          overflow: hidden;
          margin-top: -15px;
          margin-left: -15px;
        }
      }
    }
  }
}
