.popup.popup-background {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  font-family: kallisto, sans-serif;
  transition: all 0.3s;
  .popup-container {
    width: 500px;
    margin-top: 100px;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 100px #000;
    transition: all 0.3s;
    .popup-header {
      height: 50px;
      line-height: 50px;
      background-color: #323657;
      color: #fff;
      padding: 0 20px;
      font-weight: bold;
      a {
        float: right;
        color: #fff;
      }
    }
    .popup-content {
      padding: 20px;
    }
  }
  &:not(.active) {
    pointer-events: none;
    background: transparent;
    .popup-container {
      transform: scale(1.2);
      opacity: 0;
    }
  }
}
